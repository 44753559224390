var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"far fa-plus-circle\"></i> "
    + alias4(((helper = (helper = lookupProperty(helpers,"tableName") || (depth0 != null ? lookupProperty(depth0,"tableName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tableName","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":48}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"tableAreaName") || (depth0 != null ? lookupProperty(depth0,"tableAreaName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tableAreaName","hash":{},"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":67}}}) : helper)))
    + ")\n";
},"useData":true});