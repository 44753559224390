var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"table_order") : depth0)) != null ? lookupProperty(stack1,"value_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":69}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"available",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":40}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"btn btn-default w-50\" data-action=\"information\">\n            <i class=\"fas fa-2x fa-info-circle text-light\"></i>\n        </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"table_order") : depth0)) != null ? lookupProperty(stack1,"not_printed_to_production_parts") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-success w-50\" data-action=\"print\">\n                <i class=\"fas fa-2x fa-utensils text-light\"></i>\n            </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"order_empty") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":23}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"btn btn-info w-50\" data-action=\"pay\">\n                    <i class=\"fas fa-2x fa-money-bill-alt text-light\"></i>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong class=\"name\" data-checkout>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":43}}}) : helper)))
    + "</strong>\n<span class=\"creator\" data-checkout>"
    + alias4(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"table_order") : depth0)) != null ? lookupProperty(stack1,"created_by") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n<span class=\"availability "
    + alias4(((helper = (helper = lookupProperty(helpers,"availabilityClass") || (depth0 != null ? lookupProperty(depth0,"availabilityClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"availabilityClass","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":47}}}) : helper)))
    + "\" data-checkout>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"table_order") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</span>\n<i class=\"far fa-2x fa-plus-circle icon\" data-checkout></i>\n<div class=\"buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"table_order") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});