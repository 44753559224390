var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"row\">\n    <div class=\"col-md-2 text-center\">\n        <i class=\"fas fa-6x fa-info-circle text-primary\"></i>\n    </div>\n    <div class=\"col-md-10\">\n        <h3>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"there_are_no_tables_assigned_to_this_zone",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":76}}}))
    + "</h3>\n        <br>\n        <p>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"please_check_with_your_manager_heshe_is_able_to_do_this_in_the_storekeeper_backoffice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":11},"end":{"line":8,"column":119}}}))
    + "</p>\n        <p>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"ps_it_s_under_settings_hospitality_and_then_tables",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":11},"end":{"line":9,"column":84}}}))
    + "</p>\n    </div>\n</div>\n";
},"useData":true});