var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":39},"end":{"line":20,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":20,"column":55}}}) : helper)))
    + " ("
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"table_area") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + ")</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                        <option value=\"\" disabled selected>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_tables_available",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":59},"end":{"line":22,"column":101}}}))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2 class=\"pull-left\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"table_name") || (depth0 != null ? lookupProperty(depth0,"table_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"table_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":40}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"table_area_name") || (depth0 != null ? lookupProperty(depth0,"table_area_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"table_area_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":2,"column":61}}}) : helper)))
    + ")</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <form onsubmit=\"return false;\" method=\"post\">\n\n        <div class=\"form-group row\">\n            <label class=\"control-label vertical-align col-md-3\">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"from",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":65},"end":{"line":9,"column":92}}}))
    + "</label>\n            <p class=\"col-md-9\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"table_name") || (depth0 != null ? lookupProperty(depth0,"table_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"table_name","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":30}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"table_area_name") || (depth0 != null ? lookupProperty(depth0,"table_area_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"table_area_name","hash":{},"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":51}}}) : helper)))
    + ")\n            </p>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"to\" class=\"control-label vertical-align col-md-3\">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"to",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":74},"end":{"line":16,"column":99}}}))
    + "</label>\n            <div class=\"col-md-9\">\n                <select data-ui=\"to\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableTables") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":23,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </div>\n\n    </form>\n</div>\n<div class=\"dialog--footer\">\n    <button class=\"btn btn-danger btn-regular\" data-action=\"move\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTablesAvailable") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":66},"end":{"line":31,"column":115}}})) != null ? stack1 : "")
    + ">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"move_table",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":116},"end":{"line":31,"column":149}}}))
    + "</button>\n    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"close",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":68},"end":{"line":32,"column":96}}}))
    + "</button>\n</div>\n";
},"useData":true});